<template>
  <div id="app">
    <img alt="Vue logo" src="https://sitcos.guangplus.com/guangplus/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
  }
  *{
    margin: 0;
    padding: 0;
  }
  .el-submenu__title{
    font-size: 16px;
  }
</style>
