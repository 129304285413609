<template>
    <div id="app" style="overflow:-Scroll;overflow-x:hidden">
        <div class="main" >
            <div class="header">
                <div class="logo_img" @click="logoClick">
                <div style="display:flex; align-items: center; float:left;">
                    <img :src="company.img">
                </div>
                </div>
                <div id="menu_index">
                    <!-- web端菜单 -->
                    <el-menu v-if="seen" :default-active="activeIndex"  menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="0" class="header-title">{{$t('gp.home')}}</el-menu-item>
                        <el-menu-item index="1" class="header-title">{{$t('gp.product')}}</el-menu-item>
                        <el-menu-item index="2" class="header-title">{{$t('gp.company')}}</el-menu-item>
                        <el-menu-item index="5" class="header-title">{{$t('gp.aboutMe')}}</el-menu-item>
                        <el-menu-item index="3" class="header-t">中</el-menu-item>
                        <el-menu-item index="4" class="header-two">EN</el-menu-item>
                    </el-menu>

                    <!--手机端菜单-->
                    <el-menu v-if="!seen" :default-active="activeIndex"  menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelectMobile">
                        <el-submenu index="0" class="header-title">
                  <!--          <el-menu-item index="0" class="header-title">{{$t('gp.home')}}</el-menu-item>
                            <el-menu-item index="1" class="header-title">{{$t('gp.product')}}</el-menu-item>
                            <el-menu-item index="2" class="header-title">{{$t('gp.company')}}</el-menu-item>
                            <el-menu-item index="5" class="header-title">{{$t('gp.aboutMe')}}</el-menu-item>-->
                            <el-menu-item index="3" class="header-t">中</el-menu-item>
                            <el-menu-item index="4" class="header-two">EN</el-menu-item>
                        </el-submenu>
                    </el-menu>
                </div>
            </div>
            <div class="container">
                    <router-view></router-view>
            </div>

            <div style="float:left;width: 100%;margin-top: 30px">
                <div class="bottom_line">
                </div>
            </div>
            <div class="footer"  >
                <div style="float: left; width: 100%;text-align: center;">
                    <!--<p class="footer_font" >{{$t('gp.phone')}} </p>-->
                    <p class="footer_font" >{{$t('gp.mail')}}</p>
                    <p class="footer_font" >{{$t('gp.address')}}</p>
                    <p class="footer_font" >{{$t('gp.copyright')}}<a href="http://beian.miit.gov.cn" style="color: #000000;text-decoration:underline">{{$t('gp.icp')}}</a></p>
                </div>
                <div style="float: left; width: 30%;text-align: center" v-if="seen">
                    <div><img :src="company.img"></div>
                </div>
            </div>
        </div>

        <div class="backTop" ref="btn"  @click="backTop" v-if="seen" >
            <p style="font-size: 0.8em;font-weight: bold;text-align: center">TOP</p>
            <img :src="company.launch" style="height: 50px;width: 40px" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Layout",
        data() {
            return {
                activeIndex: '0',
                company:{
                    img: "https://sitcos.guangplus.com/guangplus/image.png",
                    launch: "https://sitcos.guangplus.com/guangplus/launch.png",
                },
                background: {
                    backgroundImage:'url(https://sitcos.guangplus.com/guangplus/backgroup.jpg)'
                },
                scroll: '',
                timer: '',
                seen: true,
            };
        },
        methods: {
            handleSelect(key) {     //Web端
                switch (key){
                    case '0':
                        this.$router.push({name: "Index"});
                        break;
                    case '1':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#introduce";
                        break;
                    case '2':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#enviroment";
                        break;
                    case '3':
                        if (this.$i18n.locale === 'en-US'){
                            this.$i18n.locale='zh-CN';
                        }
                        break;
                    case '4':
                        if (this.$i18n.locale === 'zh-CN'){
                            this.$i18n.locale='en-US';
                        }
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#aboutMe";
                        break;
                }
            },
            handleSelectMobile(key) {   //手机端
                switch (key){
                    case '0':
                        this.$router.push({name: "Index"});
                        break;
                    case '1':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#introduce";
                        break;
                    case '2':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#enviroment";
                        break;
                    case '3':
                        this.$i18n.locale='zh-CN';
                        break;
                    case '4':
                        this.$i18n.locale='en-US';
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#aboutMe";
                        break;
                }
            },
            handleScroll(){
                this.scroll = document.documentElement.scrollTop + document.body.scrollTop;    //获取滚动后的高度
                if( this.scroll >600 ){                               //判断滚动后高度超过400px,就显示置顶标签
                    this.$refs.btn.style.display = 'block';
                }else{
                    this.$refs.btn.style.display = 'none'
                }
            },
            backTop(){                                   //当点击TOP的时候,0.2秒的时间内滚到顶部
                this.timer = setInterval(() => {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;    //获取滚动条的纵位置
                    let ispeed = Math.floor(-osTop / 5)    //Math.floor  获取一个整数
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if (osTop === 0) {
                        clearInterval(this.timer)
                    }
                    /*document.documentElement.scrollTop =0;
                    clearInterval(this.timer);*/
                }, 20)
            },
            menuSeen(){
                let screenHeight=document.body.clientWidth;      // 获取屏幕尺寸
                if(screenHeight>415){                           //显示手机的响应式问题
                    this.seen=true;
                }else{
                    this.seen=false;
                }
            },
            logoClick(){
                this.$router.push({name: "Index"});
            }
        },
        mounted() {
            this.menuSeen()
            window.addEventListener('scroll', this.handleScroll)     //监听滚动事件
        },
    }
</script>

<style scoped>
    .logo_img{
        float:left;
        width: 50%;
        cursor: pointer;
    }
    #back_to_top{
        position: fixed;
        bottom: 50px;
        right: 30px;
        cursor: pointer            /*指向变为手指*/
    }
    .header-title{
        font-size: 17px;
        font-weight: bold;
        color: white;
        z-index: 1;
        margin-left: 2%;
    }

    .bottom_line{
        width:90%;
        margin: auto;
        height:1px;
        background-color:#999;
    }

    .backTop{
        right: 30px;
        bottom: 40px;
        display: none;
        cursor: pointer;
        position: fixed;
    }

    .header-t{
        font-size: 16px;
        color: #2b2e2e;
        z-index: 1;
        margin-right: -1%;
    }

    .header-two{
        font-size: 16px;
        color: #2b2e2e;
        z-index: 1;
    }
    .footer_font{
        font-size: 1em;
    }
    #menu_index{
        float: left; width: 50%;height: 100%
    }
    .header{
        margin: auto;
        width: 90%;
        height: 65px;
        /*background: #F56C6C;*/
    }
    @media screen and (max-device-width: 415px) {
        .logo_img{
            float:left;
            width: auto;
            cursor: pointer;
        }
        #back_to_top{
            position: fixed;
            bottom: 50px;
            right: 5px;
            cursor: pointer
        }
        .footer_font{
            font-size: 0.875em;
        }
        #menu_index{
            float: right;
            width: auto;
            height: 100%
        }

        .backTop{
            right: 20px;
            bottom: 40px;
            display: none;
            cursor: pointer;
            position: fixed;
        }
    }
    .main{
        margin: auto;
        float: left;
        width: 100%;
        border: 0;
    }
    .footer{
        display: flex;
        align-items: center;
        margin: 10px auto;
        width: 90%;

    }

    a{text-decoration: none;}
    a:visited{text-decoration: none;}
    a:hover {text-decoration: none;}
    a:active{text-decoration:none;}
</style>