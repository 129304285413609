<template>
    <div style="float: left;width: 100%" >
        <el-carousel :autoplay="true" :height=computeHeight indicator-position="outside">
            <el-carousel-item  v-for="(item,index) in sliders" :key="index">
                <div style="width: 100%;height: 100%;">
                    <img :src="item.img"  style="width: 100%;height: 100%;">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="border_parent" v-if="seen">
            <div class="border border_one" :style="backgroundImg" @mouseover="openImg"  > <!---->
                <div class="border_title">简介</div>
                <div class="border_content_one" style="color: #ff8c00;text-align: center">INTRODUCE</div>
                <div class="line">
                </div>
                <div class="border_content_two">{{$t('gp.introduce')}}</div>
            </div>
            <div class="border border_one" :style="backgroundImg1" @mouseover="openImg1"  >  <!---->
                <div class="border_title" >使命</div>
                <div class="border_content_one" style="color: darkorange;text-align: center">MISSION</div>
                <div class="line">
                </div>
                <div class="border_content_two" >{{$t('gp.mission')}}</div>
            </div>
            <div class="border border_one" :style="backgroundImg2" @mouseover="openImg2"  >   <!---->
                <div class="border_title">团队</div>
                <div class="border_content_one"  style="color: darkorange;text-align: center">TEAM</div>
                <div class="line">
                </div>
                <div class="border_content_two">{{$t('gp.team')}}</div>
            </div>
        </div>

        <div class="border_parent" v-if="!seen">
            <div class="border1">
                <div class="border_title1" :style=" backgroupImg3">简介
                    <div style="color: whitesmoke;font-size: 0.6em;">INTRODUCE</div>
                </div>

                <div class="border_title2" :style="backgroupImg4">{{$t('gp.introduce')}}</div>
            </div>
            <div class="border1">
                <div class="border_title2" :style="backgroupImg4" >{{$t('gp.mission')}}</div>

                <div class="border_title1" :style=" backgroupImg3">使命
                    <div style="color: whitesmoke;font-size: 0.6em;">MISSION</div>
                </div>

            </div>
            <div class="border1">
                <div class="border_title1" :style=" backgroupImg3">团队
                    <div style="color: whitesmoke;font-size: 0.6em;">TEAM</div>
                </div>

                <div class="border_title2" :style="backgroupImg4">{{$t('gp.team')}}</div>
            </div>
        </div>

        <div id="introduce" style="display:flex; margin-top: 80px" v-if="seen">
            <div style="width: 100%; margin: auto;text-align: center" class="font_size_24">
                {{$t('gp.product')}}
            </div>
        </div>



        <div class="product_pc1" v-if="seen">
            <div class="product_video">
                <video
                        class="product_pc_img1"
                        style="width: 100%;height:100%;border-radius: 25px;"
                        controls
                        controlslist="nodownload"
                        muted
                        playsinline
                        :src="manages[1].movie[0]">
                </video>

            </div >
            <div style="width: 100%;height: 100%;">
                <div style="margin-top: 8%">
                    <span class="business_font">{{$t('gp.business')}}</span>
                    <div  class="business_text">
                        {{$t('gp.direction')}}
                    </div>
                </div>


                <div style="margin-top: 7%">
                    <span class="project_font">{{$t('gp.project')}}</span>
                    <div  class="project_text">
                        {{$t('gp.pIntroduce')}}
                    </div>
                </div>



                <div style="margin-top: 7%">
                     <span class="overseas_font">{{$t('gp.overseas')}}</span>
                    <div  class="overseas_text">
                        {{$t('gp.cooperation')}}

                    </div>
                </div>



                <div class="product_link"><a style="text-decoration: none" href="javascript;" @click.prevent="href">{{$t('gp.link')}}</a></div>

            </div>
        </div>

        <div class="product_mobile" :style="backgroupImg5" v-if="!seen">
            <div  style="display:flex; flex: 1; flex-direction: column; margin-top: 20px;" >
                <div style="width: 100%; height:100%;text-align: center; color: whitesmoke;"  class="font_size_24">
                    {{$t('gp.product')}}
                </div>

                <div class="product_video" style="margin-top: 3%">
                    <video
                            class="product_pc_img1"
                            style="width: 70%;height:100%;margin-left: 15% "
                            controls
                            controlslist="nodownload"
                            muted
                            playsinline
                            :src="manages[1].movie[0]">
                    </video>

                    <!--loop 重新开始播放  muted 视频播放的时候音频也会播放 -->
                </div >
            </div>
            <div style="text-align: center"><a style="text-decoration: none" href="javascript;" @click.prevent="href">{{$t('gp.link')}}</a></div>


            <div style="display:flex; flex: 1; flex-direction: column; margin-top: 30px;">
                <div style="width: 80px;height: 80px;border-radius: 50%;margin: 0 auto">
                    <img :src="manages[0].img[6]">
                </div>
                <div style="text-align: center;margin-top: 2%" class="font_size_24">{{$t('gp.business')}}</div>
                <div style="text-indent: 1.5em;padding: 2% 13%; text-align: left;">{{$t('gp.direction')}}</div>
            </div>

            <div style="display:flex; flex: 1; flex-direction: column; margin-top: 30px;">
                <div style="width: 80px;height: 80px;border-radius: 50%;margin: 0 auto">
                    <img :src="manages[0].img[7]"></div>
                <div style="text-align: center;;margin-top: 2%" class="font_size_24">{{$t('gp.project')}}</div>
                <div style="text-indent: 1.5em;padding: 2% 13%; text-align: left;">{{$t('gp.pIntroduce')}}</div>
            </div>

            <div style="display:flex; flex: 1; flex-direction: column; margin-top: 30px;">
                <div style="width: 80px;height: 80px;border-radius: 50%;margin: 0 auto">
                    <img :src="manages[0].img[8]">
                </div>
                <div style="text-align: center;margin-top: 2%" class="font_size_24">{{$t('gp.overseas')}}</div>
                <div style="text-indent: 1.5em;padding: 2% 13%; text-align: left;">{{$t('gp.cooperation')}}</div>
            </div>

        </div>




        <div id="enviroment" class="cooperation" v-if="seen">

            <div style="margin: auto;width: 100%;text-align: center" class="font_size_24">
                {{$t('gp.company')}}
            </div>

        </div>

        <div class="envionment_product" v-if="seen">

                <img :src="manages[0].img[1]" style="display: flex;flex: 1;height: 100%; border: 7px solid snow;">

            <div style="display: flex;flex: 3; flex-direction:column;height: 100%;background-color: #999999">
                <div style="display: flex;width: 100%;height: 50%">

                    <img :src="manages[0].img[2]"  style="width: 50%;border: 7px solid snow;">
                    <img :src="manages[0].img[0]" style="width: 50%;border: 7px solid snow;">
                </div>
                <div style="display: flex;width: 100%;height: 50%">

                    <img :src="manages[0].img[3]" style="width: 50%;border: 7px solid snow;">
                    <img :src="manages[0].img[5]" style="width: 50%;border: 7px solid snow;"/>
                </div>

            </div>

            <img :src="manages[0].img[4]" style="display: flex;flex: 1;height: 100%;border: 7px solid snow;">
        </div>


        <div class="product_mobile1" v-if="!seen" :style="backgroupImg5">
            <div  style="display:flex; flex: 0.5; flex-direction: column; margin-top: 20px;" >
                <div style="width: 100%; height:100%;text-align: center; color: whitesmoke;"  class="font_size_24">
                    {{$t('gp.company')}}
                </div>
            </div>

            <div style="width:80%; display: flex; flex: 2;margin: 0 auto">
                <div style="display: flex; flex-direction: column; flex: 1 ">
                    <img :src="manages[0].img[0]"  style="width: 100%;height:50%;border: 1px solid snow;">
                    <img :src="manages[0].img[2]"  style="width: 100%;height:50%;border: 1px solid snow;">
                </div>
                <img :src="manages[0].img[4] " style="width: 50%;height:50%;flex:1;border: 1px solid snow;">
            </div>

            <div style="width:80%; display: flex; flex: 2;margin: 0 auto">
                <img :src="manages[0].img[1] " style="width: 50%;height:50%;flex:1;border: 1px solid snow;">
                <div style="display: flex; flex-direction: column; flex: 1 ">
                    <img :src="manages[0].img[3]"  style="width: 100%;height:50%;border: 1px solid snow;">
                    <img :src="manages[0].img[5]"  style="width: 100%;height:50%;border: 1px solid snow;">
                </div>
            </div>
            <!--<img :src="manages[0].img[13]" class="img2"/>-->
        </div>


        <!-- 关于我们-->
        <div id="aboutMe" class="about_me" v-if="seen" >
            <div style="width: 90%;text-align: center" class="font_size_24">

                {{$t('gp.aboutMe')}}
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'Index',
        data() {
            return {
                backgroundImg:{
                    backgroundImage: 'url(https://sitcos.guangplus.com/guangplus/company.jpg)',
                    backgroundSize:'520px 320px'
                },
                backgroundImg1:{
                    backgroundImage: '',
                    backgroundSize:'520px 320px'
                },
                backgroundImg2:{
                    backgroundImage: '',
                    backgroundSize:'520px 320px'
                },
                backgroupImg3:{
                    backgroundImage:'url( https://sitcos.guangplus.com/guangplus/bgrey.jpg)'
                },
                backgroupImg4:{
                    backgroundImage:'url( https://sitcos.guangplus.com/guangplus/grey.jpg)'
                },
                backgroupImg5:{
                    backgroundImage:'url( https://sitcos.guangplus.com/guangplus/backgroup.jpg)',
                },

                sliders: [],

                seen: true,

                manages: [
                    {
                         img: ['https://sitcos.guangplus.com/guangplus/gp1.jpg','https://sitcos.guangplus.com/guangplus/gp5.jpg','https://sitcos.guangplus.com/guangplus/gp6.jpg','https://sitcos.guangplus.com/guangplus/gp7.jpg','https://sitcos.guangplus.com/guangplus/gp8.jpg',
                             'https://sitcos.guangplus.com/guangplus/gp9.jpg','https://sitcos.guangplus.com/guangplus/idea.png','https://sitcos.guangplus.com/guangplus/operation.png','https://sitcos.guangplus.com/guangplus/service.png','https://sitcos.guangplus.com/guangplus/gp10.jpg','https://sitcos.guangplus.com/guangplus/gp11.jpg',
                             'https://sitcos.guangplus.com/guangplus/gp12.jpg','https://sitcos.guangplus.com/guangplus/gp13.jpg','https://sitcos.guangplus.com/guangplus/gp14.jpg'],

                    },
                    {
                        movie:['https://sitcos.guangplus.com/guangplus//movie.mp4']
                    },


                ],
            }
        },
        methods: {
            openImg(){
                this.backgroundImg1.backgroundImage=''
                this.backgroundImg.backgroundImage='url(https://sitcos.guangplus.com/guangplus/company.jpg)';
                this.backgroundImg2.backgroundImage=''
            },
            openImg1(){
                this.backgroundImg.backgroundImage=''
                this.backgroundImg1.backgroundImage='url(https://sitcos.guangplus.com/guangplus/dream1.jpg)';
                this.backgroundImg2.backgroundImage=''
            },

            openImg2(){
                this.backgroundImg.backgroundImage=''
                this.backgroundImg1.backgroundImage=''
                this.backgroundImg2.backgroundImage='url(https://sitcos.guangplus.com/guangplus/re.jpg';
             },
            href(){
                window.open('https://www.flashdelivery.net/home');
            },
            menuSeen(){
                /*let screenHeight=document.body.clientWidth;*/      // 获取屏幕尺寸
                if(!this.isMobile()){                           //显示手机的响应式问题
                    this.seen=true;
                }else{
                    this.seen=false;
                }
            },
            isMobile() {
                return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            }
        },
        mounted() {
            this.sliders = [
                {
                    img: "https://sitcos.guangplus.com/guangplus/gp.jpg"
                },
                {
                    img: "https://sitcos.guangplus.com/guangplus/gp2.jpg"
                },
                {
                    img: 'https://sitcos.guangplus.com/guangplus/gp3.jpg'
                },
            ]
        },
        created() {
            this.menuSeen()
        },
        computed: {
            //计算高度
            computeHeight() {
                /*let screenHeight = document.body.clientWidth;*/
                if (!this.isMobile()) {
                    return '900px';
                } else {
                    return '250px';
                }
            },

        }
    }
</script>

<style scoped>
    .border_parent {
        display: flex;
        width: 100%;
        margin-left: 22%;
        height: auto
    }


    .product_link{
        font-size: 18px;
        padding-left: 90%;
        padding-top: 11%;
    }

    .business_text{
        padding-left: 15%;
        padding-top: 2%;
        font-size: 18px;
        font-weight:bold;
        color: grey
    }

    .project_text{
        padding-left: 20%;
        padding-top: 2%;
        font-size: 18px;
        font-weight:bold;
        color: grey
    }



    .overseas_text{
        padding-left: 25%;
        padding-top: 2%;
        font-size: 18px;
        font-weight:bold;
        color: grey
    }


    .business_font{
        font-size: 22px;
        font-weight: bold;
        padding-left: 2%;
    }

    .project_font{
        font-size: 22px;
        font-weight: bold;
        padding-left: 7%
    }

    .overseas_font{
        font-size: 22px;
        font-weight: bold;
        padding-left: 12%
    }

    .product_video{
        width: 50%;
        height: 100%;
        flex-shrink: 0;
    }




    .envionment_product{
        width: 90%;
        height: 800px;
        background: #FBFBFB;
        display: flex;
        align-items: center;
        margin: 20px auto;
    }

    .border {
        border-radius: 25px;
        border: 1px solid #EBEEF5;
        background-color: rgba(255,255,255,0.2);
        float: left;
        width: 20%;
        height: 90%;
        margin: 45px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background-color: #FBFBFB;
        /*background: aquamarine;*/
    }

    .border1{
        display: flex;
        flex: 1;
        height: 90%;
        text-align: center;
        vertical-align: middle;
    }

    .border_title1{
        flex: 1;
        width: 50%;
        height: auto;
        vertical-align: middle;
        padding: 5% 0 10% 0;
        font-weight: bold;
        color: whitesmoke;
        font-size: 2.2em;
        border:2px solid #EBEEF5 ;
    }

    .border_title2{
        flex: 1;
        width: 50%;
        height: auto;
        padding: 15px 0 0 0;
        text-align: left;
        text-indent:1.5em;
        border:1px solid #EBEEF5 ;
    }

    .border_one {
        margin-left: 0;
    }



    .product_pc_img1 {
        display:block ;
        height: 50%;
        width: 50%;
    }


     .product_pc1 {
        border-radius: 25px;
        display: flex;                   /*  水平方向均分  */
        width: 90%;
        margin: 20px auto;
        background: #FBFBFB;
    }




    .border_title {
        font-family: "PingFang SC";
        margin-top: 40px;
        font-size: 2.2em;
        font-weight: bold;
        margin-left: 10%;
    }

    .border_content_one {
        margin-top: 5px;
        font-size: 2em;
        font-weight: bold;
        margin-left: 5%;

    }

    .border_content_two {
        margin-top: 30px;
        font-size: 1.125em;
        font-weight: bold;
        height: 120px;
        text-indent: 2em;
    }

    .line {
        width:100%;
        margin-top:35px;
        height:2px;
        text-align:center ;
        padding:0;
        background-color:#DCDCDC;
    }



    .cooperation {
        display: flex;
        margin-top: 130px;
        justify-content: center;
        width: 100%;
    }

    .about_me {
        display: flex;
        margin-top: 80px;
        justify-content: center;
        width: 100%;
    }


    /* 屏幕设备适配 max-device-width: 400px */
    @media screen and (max-device-width: 550px) {

        .product_link{
            font-size: 16px;
            padding-left: 75%;
            padding-top: 10%;
        }

        .product_pc1 {
            border-radius: 25px;
            display: flex;                   /*  垂直方向均分  */
            flex-direction: column;
            width: 90%;
            margin: 20px auto;
            background: #FBFBFB;
        }


        .product_mobile{
            display: flex;                   /*  垂直方向均分  */
            flex-direction: column;
            width: 100%;
            margin-top: 8%;
        }

        .product_mobile1{
            display: flex;                   /*  垂直方向均分  */
            flex-direction: column;
            width: 100%;
            margin-top: 8%;
        }


        .business_text{
            padding-top: 2%;
            font-size: 16px;
            color: grey
        }

        .project_text{
            padding-left: 15%;
            padding-top: 2%;
            font-size: 16px;
            color: grey
        }

        .overseas_text{
            padding-left: 15%;
            padding-top: 2%;
            font-size: 16px;
            color: grey
        }



        .product_video{
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }

        .business_font{
            font-size: 22px;
            font-weight: bold;
        }

        .project_font{
            font-size: 22px;
            font-weight: bold;
            padding-left: 2%;
        }

        .overseas_font{
            font-size: 22px;
            font-weight: bold;
            padding-left: 2%;
        }

        .border_parent {
            display: flex;
            width: 100%;
            margin: auto;
            height: auto;
            flex-direction: column;
        }



        .product_pc_img1 {
            height: 100%;
            width: 100%;
            background-color: #DCDCDC;
        }


        .border {
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            float: left;
            width: 100%;
            height: auto;
            margin: 10px 0 auto;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            background-color: #FBFBFB;
            /*background: aquamarine;*/
        }




        .about_me {
            display: flex;
            margin-top: 10px;
            justify-content: center;
            width: 100%;
        }
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    .font_size_24 {
        font-size: 1.5em;
        font-weight: bold;
    }
</style>