export const gp = {
    home: '首页',
    product: '产品介绍',
    company: '公司环境',
    aboutMe: '关于我们',
    introduce: ' 广州广加科技有限公司是一家以互联网为基础的公司，秉承着”科技传递美好生活“的理念。',
    mission: '通过互联网思维帮助企业进行转型,使企业互联网化,提高企业的运营效率。',
    team:'一群小伙伴因梦想聚集在一起,坚信科技改变世界。',
    business:'理念宗旨',
    direction:'致力于打造东南亚最专业的本地生活服务平台, 提升当地人民的生活品质，助力当地餐饮、零售、服务等行业的数字化升级，为客户提供最专业、最便利的服务。',
    project:'本地化运营',
    pIntroduce:'建立国内产研中心与海外运营中心运作模式，通过数字化经营、专业化生产、多样化营销和智慧化服务四大服务解决方案，提供更多的互联网营销工具，解决商家运营“痛点”。',
    overseas:'电商服务',
    cooperation:'目前菲快送平台拥有稳定供应商和优质的服务团队，以大数据分析、AI等技术支持，扩大服务范围、优化业务体系，推出更加智能化、本地化、多元化的电商服务平台，为用户提供更极致、便捷的使用体验。',
    environment:'员工可享受最佳的视觉景观和自然采光，共同创造一个平等、和谐、多元文化融合发展的工作环境',
    phone:'电话：020-22836025',
    mail:'邮箱：gplus@guangplus.com',
    address:'地址：广州市天河区莲溪深涌南二路3号广州莲溪智慧湾区科技园1栋102 -202',
    copyright:' Copyright ©2021 广州广加科技有限公司 版权所有丨',
    icp: '粤ICP备19071864号',
    link:'产品链接'

}